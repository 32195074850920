import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {PricingOptionsTotalProps} from './interfaces'

export const PricingOptionTotal = ({total}: PricingOptionsTotalProps) => {
  const {t} = useTranslation()
  return (
    <p role="cell" aria-label={`${t('ticketsPicker.subtotal')}: ${total}`}>
      {total}
    </p>
  )
}
