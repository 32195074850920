import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {getFlexJustifyContentClass} from '../../../../alignment'
import {Tax} from '../../../parts/tax'
import {WixFee} from '../../../parts/wix-fee'
import s from './pricing-option-price.scss'
import {PricingOptionsPriceProps} from './interfaces'

export const PricingOptionPrice = ({
  pricingOption,
  className,
  ticket,
  isMobile,
  contentAlignment,
}: PricingOptionsPriceProps) => {
  const price = getFormattedMoney(pricingOption.price)
  const {t} = useTranslation()

  return (
    <div className={className} role="cell">
      <p data-hook="price" aria-label={t('mobile.thanks.price', {price})}>
        {price}
      </p>

      <div
        className={classNames({
          [s.taxFeeContainer]: isMobile,
          [getFlexJustifyContentClass(contentAlignment)]: isMobile,
        })}
      >
        <Tax ticket={ticket} overridePrice={pricingOption.price.amount} />
        <WixFee ticket={ticket} overridePrice={pricingOption.price.amount} />
      </div>
    </div>
  )
}
