import classNames from 'classnames'
import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {PricingOption} from '@wix/ambassador-wix-events-web/types'
import c from '../../../../../classnames.scss'
import {PricingOptionPrice} from '../pricing-option-price'
import s from './pricing-option-name.scss'

export const PricingOptionName = ({
  pricingOption,
  ticket,
}: {
  pricingOption: PricingOption
  ticket: wix.events.ticketing.TicketDefinition
}) => {
  const {t} = useTranslation()
  const {name} = pricingOption

  return (
    <>
      <h4
        className={classNames(c.evTicketNameColor, c.evTicketNameFont)}
        data-hook="name"
        role="cell"
        aria-label={`${t('ticketsThankYou.ticketName')}: ${name}`}
      >
        {name}
      </h4>
      <PricingOptionPrice
        className={classNames(s.price, c.evTextFont, c.evTextColor)}
        ticket={ticket}
        pricingOption={pricingOption}
      />
    </>
  )
}
