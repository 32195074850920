import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import c from '../../../../classnames.scss'
import {getTax} from '../../../../../selectors/tax-and-fee'
import s from './tax.scss'
import {TaxProps} from './index'

export const Tax = ({t, ticket, event, className, overridePrice}: TaxProps) => {
  const abstract = isDonationTicketDefinition(ticket) && !overridePrice
  const tax = getTax({event, ticket, t, overridePrice, abstract})

  if (!tax) {
    return null
  }

  return (
    <p
      className={classNames(c.evTicketAdditionalInfoColor, s.tax, className)}
      data-hook={DH.TAX_LABEL}
      aria-label={t('mobile.thanks.tax', {tax})}
    >
      {tax}
    </p>
  )
}
