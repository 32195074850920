import {
  getFormattedPrice,
  saleScheduled,
  saleStartedAndHasTickets,
  isDonationTicketDefinition,
  getPricingOptions,
  hasPricingOptions,
} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {calculateSubPrice} from '../../../../services/payment'
import c from '../../../classnames.scss'
import {ColumnConfig, TableRow} from '../../../table-row'
import {FixedHeightColumn} from '../parts/fixed-height-column'
import {LimitReachedTooltip} from '../parts/limit-reached-tooltip'
import {Price} from './columns/price'
import {Quantity} from './columns/quantity'
import {TicketNameColumn} from './columns/ticket-name-column'
import s from './ticket.scss'
import {TotalColumn} from './columns/total-column'
import {PricingOption} from './pricing-option'
import {TicketProps} from './index'

export const Ticket = ({
  ticket,
  selectedQuantity,
  selectedDonation,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  withSeatingPlan,
  isEventEnded,
  donationInputVisible,
}: TicketProps) => {
  const {id, limitPerCheckout, price} = ticket
  const showPricingOptions = hasPricingOptions(ticket) && !registrationClosed && !withSeatingPlan
  const pricingOptions = getPricingOptions(ticket)
  const ticketPrice = Number(isDonationTicketDefinition(ticket) ? selectedDonation ?? 0 : price.amount)
  const total = getFormattedPrice(calculateSubPrice(ticketPrice, selectedQuantity), price.currency)

  const nameColumn: ColumnConfig = {
    className: s.nameColumn,
    content: (
      <div className={classNames(s.nameContainer, {[s.nameColumnDonation]: donationInputVisible})}>
        <TicketNameColumn ticket={ticket} />
      </div>
    ),
  }

  const priceColumn: ColumnConfig = {
    content: (
      <FixedHeightColumn
        dataHook={DH.TICKET_PICKER_PRICE_COLUMN}
        alignedToTop={donationInputVisible}
        className={classNames({
          [s.total]: withSeatingPlan && registrationClosed && !saleScheduled(ticket),
        })}
        ticketDefId={id}
      >
        <Price ticket={ticket} />
      </FixedHeightColumn>
    ),
  }

  const totalColumn: ColumnConfig = {
    content: (
      <FixedHeightColumn
        alignedToTop={donationInputVisible}
        className={classNames(s.total, {[s.totalColumnDonation]: donationInputVisible})}
        ticketDefId={id}
      >
        <TotalColumn
          total={total}
          ticket={ticket}
          withSeatingPlan={withSeatingPlan}
          registrationClosed={registrationClosed}
          isEventEnded={isEventEnded}
        />
      </FixedHeightColumn>
    ),
  }

  const getWithSeatingsColumns = () => {
    if (registrationClosed && !saleScheduled(ticket)) {
      return [nameColumn, {content: ''}, {content: ''}, priceColumn]
    }
    return [nameColumn, priceColumn, {content: ''}, totalColumn]
  }

  const columns: ColumnConfig[] = withSeatingPlan
    ? getWithSeatingsColumns()
    : [
        nameColumn,
        priceColumn,
        {
          content: saleStartedAndHasTickets(ticket) && !showPricingOptions && (
            <FixedHeightColumn
              alignedToTop={donationInputVisible}
              ticketDefId={id}
              className={classNames({[s.quantityColumnDonation]: donationInputVisible})}
            >
              <LimitReachedTooltip ticket={ticket} minWidth={140}>
                <Quantity
                  selectedQuantity={selectedQuantity}
                  availableTicketsQuantity={availableTicketsQuantity}
                  limit={limitPerCheckout}
                  disabled={!limitPerCheckout || registrationClosed}
                  onChange={onChange}
                  id={id}
                />
              </LimitReachedTooltip>
            </FixedHeightColumn>
          ),
          className: s.overflow,
        },
        showPricingOptions ? {content: ''} : totalColumn,
      ]

  return (
    <div className={classNames(c.evTextFont, c.evTextColor)}>
      <div className={s.rows}>
        <TableRow
          config={{
            itemKey: id,
            responsiveHideColumns: [1],
            className: classNames(s.row, {[s.rowPricingOptionsHeader]: showPricingOptions}),
            dataHook: DH.TICKET,
            columns,
          }}
        />
        {showPricingOptions
          ? pricingOptions?.map(pricingOption => (
              <PricingOption key={pricingOption.id} pricingOption={pricingOption} ticket={ticket} />
            ))
          : null}
      </div>
      <div>
        <div className={classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor)} />
      </div>
    </div>
  )
}
