import React, {useState, useEffect} from 'react'
import {TextField} from 'wix-ui-tpa'
import {
  getCurrencySymbol,
  isCurrencySuffixed,
  normalizePrice,
  getFormattedPrice,
  minimumDonation,
  MAX_PRICE,
} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {DONATION_ERROR} from '../../types'
import {DonationInputProps} from './interfaces'

export const DonationInput = ({
  ticket,
  onChange,
  onBlur,
  value,
  error,
  label,
  className,
  ignoreMinimum,
}: DonationInputProps) => {
  const {t} = useTranslation()
  const [localValue, setLocalValue] = useState(value ?? '')

  const currency = ticket.price.currency
  const suffixedCurrency = isCurrencySuffixed(currency)
  const symbol = getCurrencySymbol(currency)
  const minimum = ignoreMinimum ? 0 : minimumDonation(ticket)

  useEffect(() => {
    if (value === undefined) {
      setLocalValue(value?.toString() ?? '')
    }
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 0) {
      onChange(undefined)
      setLocalValue('')
    } else {
      const normalizedValue = normalizePrice(event.target.value, localValue?.toString(), {max: MAX_PRICE})
      onChange(normalizedValue !== undefined ? normalizedValue : undefined)
      setLocalValue(normalizedValue !== undefined ? normalizedValue : '')
    }
  }

  const getError = () => {
    const ERRORS = {
      [DONATION_ERROR.EMPTY_DONATION]: () => t('tickets.donationError.empty'),
      [DONATION_ERROR.MINIMUM_NOT_REACHED]: () =>
        t('tickets.donationError.minimum', {price: getFormattedPrice(minimum, currency)}),
    }
    return ERRORS[error]()
  }

  return (
    <div onClick={event => event.stopPropagation()}>
      <TextField
        data-hook={DH.DONATION_INPUT}
        value={localValue ?? ''}
        newErrorMessage
        className={className}
        label={label ?? t('tickets.donationInputLabel')}
        placeholder={minimum ? t('tickets.donationMinimumPlaceholder', {minimum}) : ''}
        error={Boolean(error)}
        errorMessage={error ? getError() : undefined}
        onChange={handleChange}
        onBlur={onBlur}
        prefix={suffixedCurrency ? undefined : symbol}
        suffix={suffixedCurrency ? symbol : undefined}
      />
    </div>
  )
}
