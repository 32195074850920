import {getFormattedMoney, isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {Tax} from '../../../parts/tax'
import {WixFee} from '../../../parts/wix-fee'
import {getFlexJustifyContentClass} from '../../../../alignment'
import {DONATION_ERROR} from '../../../../../../types'
import {Donation} from '../../../parts/donation'
import {checkDonationError} from '../../../../../../services/donations'
import s from './price.scss'
import {PriceProps} from './index'

export const Price = ({
  t,
  className,
  ticket,
  isMobile,
  donationInputVisible,
  contentAlignment,
  changeTicketDonation,
  donation,
  donationError,
  setDonationError,
}: PriceProps) => {
  const donationTicket = isDonationTicketDefinition(ticket)
  const price = donationTicket ? t('tickets.donation') : getFormattedMoney(ticket.price)

  const onDonationChange = (donation: string) => {
    changeTicketDonation({ticketId: ticket.id, donation})
  }

  const onDonationBlur = () => {
    const error = checkDonationError(ticket, donation, {excludeErrors: [DONATION_ERROR.EMPTY_DONATION]})
    if (error) {
      setDonationError({ticketId: ticket.id, error})
    }
  }

  return (
    <div className={className} role="cell">
      {donationInputVisible ? (
        <Donation
          ticket={ticket}
          value={donation}
          onChange={onDonationChange}
          onBlur={onDonationBlur}
          error={donationError}
        />
      ) : (
        <p data-hook="price" aria-label={t('mobile.thanks.price', {price})}>
          {price}
        </p>
      )}

      <div
        className={classNames({
          [s.taxFeeContainer]: isMobile,
          [getFlexJustifyContentClass(contentAlignment)]: isMobile,
        })}
      >
        <Tax ticket={ticket} overridePrice={donation} />
        <WixFee ticket={ticket} overridePrice={donation} />
      </div>
    </div>
  )
}
